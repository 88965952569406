import React, { useEffect, useMemo, useState } from 'react'
import NewCard from '../../../../components/NewCard'
import { ROOT } from '../../../../utils/Routes';
import { IMAGES, Icons } from '../../../../assets';
import { ArrowButton, BasicButton } from '../../../../components/NewButtons';
import { useNavigate } from 'react-router-dom';
import { InputBordered } from '../../../../components/NewInputs';
import { FormatMoney, empty } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { s_session_token } from '../../../../reducer/SessionReducer';
import config from '../../../../config';
import './tickets.css'
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import { SmallSpinner } from '../../../../components/Spinner';
import { WebPayButton } from '../../../../components/WebpayButton';
import { DeleteModal } from '../../../../components';
import { setBuyTicket } from '../../../../reducer/TicketsReducer';

export const BuyTicketsNew = ({ online }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector(s_session_token);
    const { asyncFetch, error, loading: fetching } = useApi(resources['events'], { auto: false });
    const { asyncFetch: asyncFetchCards, error: cardError, loading: isLoading } = useApi(resources['getCards'], { auto: false });
    const [cards, setCards] = useState([])
    const { asyncFetch: asyncFetchDelete, error: deleteError, loading: deleting } = useApi(resources['deleteCard'].replace(':digits', cards[0]?.CreditCardLastNumbers), { auto: false });

    const [step, setStep] = useState(1)
    const [preVenta1, setPreVenta1] = useState(0)
    const [general, setGeneral] = useState(0)
    const [vip, setVIP] = useState(0)

    const [fullName, setFullName] = useState('')
    const [emailOrPhone, setEmailOrPhone] = useState('')
    const [rut, setRut] = useState('')

    const [myArr, setMyArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [type, setType] = useState(null);
    const [frinds, setfriends] = useState([]);
    const [startValue, setStart] = useState(3);
    const [isModal, setModal] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const toggleModal = () => setModal(!isModal)

    let totlaTickets = preVenta1 + vip + general
    const count = totlaTickets;

    useEffect(() => {
        FetchData()
        // console.log('cardRes')
    }, [])

    const FetchData = async () => {
        let res = await asyncFetch()
        let cardRes = await asyncFetchCards()
        const events = res.filter(v => online ? v.location === 'online' : v.location !== 'online')
        const tics = events[0]?.tickets
        let allTickets = await tics?.map((_) => { return { ..._, count: 0 } })
        setTickets(allTickets)
        if (cardRes[0]?.CreditCardType != null) {
            setCards(cardRes)
        }
        else {
            setCards([])
        }
    }

    async function buyTicket(endpoint, body) {
        try {
            // setLoading(true)
            const reply = await fetch(`${config.API_URL}/store/event/buy_tickets/shisosa-party/${endpoint}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(body)
            });
            return await reply.json();
        } catch (error) {
            console.log('error', error)
            setLoading(false)
        }
    }

    const Payment = async (type) => {
        try {
            dispatch(setBuyTicket({ type: online ? 'ONLINE' : '' }))
            setLoading(true)
            let endpoint = type == 1 ? 'flow' : type == 2 ? 'card' : type == 3 ? 'addCreditCard' : null
            const body = {
                buyer: {
                    name: fullName,
                    contact_info: emailOrPhone,
                    rut: rut,
                    ticket_layer: 'buyer',
                },
                friends: frinds,
            }
            let reply = await buyTicket(endpoint, body)
            if (reply && endpoint !== 'card') {
                window.location.replace(reply.payment_url)
            }
            if (reply && endpoint == 'card') {
                if (reply?.message == "valid") navigate('/store/success')
                else navigate('/store/fail')
            }
            if (reply === undefined) {
                navigate(-1)
                alert("Something went wrong please start the process again")
            }
            setLoading(false)
            setType(null)
        } catch (error) {
            console.log('error', error)
        }
    }

    const HandleAdd = (idx) => {
        let d = [...tickets]
        d[idx].count = d[idx].count + 1
        setTickets(d)
    }

    const HandleSubtract = (idx) => {
        let d = [...tickets]
        d[idx].count = d[idx].count - 1
        setTickets(d)
    }

    const HandleContinue = (type) => {
        setType(type)
        Payment(type)
    }

    const dataCard = {
        photo: IMAGES.TICKETSBUY,
        title: '',
    };

    let index = step - 3
    const ticketsCount = tickets.filter((item) => item.count > 0)
    let currentTicket = ticketsCount[index]
    const arrayOfObjects = createArray(currentTicket?.count, currentTicket?.ticket_slug);
    const [data, setData] = useState(arrayOfObjects)

    let arraySteps = startValue - 1 + ticketsCount?.length
    const ShowArray = step >= startValue && step <= arraySteps
    const totlCount = ticketsCount.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
    }, 0)
    const total = ticketsCount.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count * currentValue.price;
    }, 0);


    const handleBack = async () => {
        if (step === 1) navigate(ROOT)
        else {
            setStep(Math.max(1, step - 1))
            let cur = ticketsCount[step - 4]?.ticket_slug ?? false
            let filtered = frinds.filter(f => f.ticket_layer !== cur)
            setfriends(filtered)
        }
    }

    useMemo(() => {
        const myArray = Array.from({ length: totlCount }, (_, index) => ({ name: '', ticket_layer: '' }));
        setMyArr(myArray)
    }, [totlCount])

    const handleGuestChange = (index, field, layer, value) => {
        const updatedGuests = [...data];
        updatedGuests[index][field] = value;
        updatedGuests[index]['ticket_layer'] = layer;
        setData(updatedGuests);
    };

    useEffect(() => {
        setData(arrayOfObjects);
    }, [step])

    const OnCountinueTicket = (data) => {
        if (frinds?.length) setfriends([...frinds, ...data])
        else setfriends([...data])
        setStep(step + 1)
    }

    const HandleDelete = async () => {
        try {
            setDeleting(true)
            await asyncFetchDelete()
            await asyncFetchCards()
            setDeleting(false)
            toggleModal()
            setCards([])
        } catch (error) {
            setDeleting(false)
        }
    }

    return (
        <div className='bg-white h-[100svh] overflow-scroll scrollbar-hidden'>
            <NewCard
                className={'h-[45svh] bg-top'}
                data={dataCard} onBack={handleBack} />
            <div className='px-6'>
                <Steps step={step}
                    ShowArray={ShowArray}
                    showPayment={Number(tickets?.length) + startValue == step} />
                {step == 1 &&
                    <>
                        {fetching || isLoading ?
                            <div className='mt-[15vh] flex items-center justify-center self-center'>
                                <SmallSpinner />
                            </div>
                            : <Step1
                                tickets={tickets}
                                HandleAdd={HandleAdd}
                                HandleSubtract={HandleSubtract}
                                preVenta1={preVenta1}
                                setPreVenta1={setPreVenta1}
                                setVIP={setVIP}
                                vip={vip}
                                setGeneral={setGeneral}
                                general={general}
                                totlaTickets={totlaTickets}
                                onContinue={() => setStep(2)}
                            // onContinue={Payment}
                            />}
                    </>
                }
                {step == 2 &&
                    <Step2
                        rut={rut}
                        fullName={fullName}
                        emailOrPhone={emailOrPhone}
                        onChangeFullName={e => setFullName(e.target.value)}
                        onChangeEmailOrPhone={e => setEmailOrPhone(e.target.value)}
                        onChnageRut={e => setRut(e.target.value)}
                        // isChecked={isChecked}
                        // setIsChecked={setIsChecked}
                        onContinue={() => setStep(3)}
                    />}
                {Boolean(ticketsCount?.length) && <>
                    {ShowArray && <>
                        <ArrayOfSteps
                            data={data}
                            currentTicket={currentTicket}
                            handleGuestChange={handleGuestChange}
                            onContinue={OnCountinueTicket} />
                    </>}
                </>}
                {step == Number(ticketsCount?.length) + startValue &&
                    <PaymentStep
                        cards={cards}
                        type={type}
                        totlCount={totlCount}
                        total={total}
                        HandleDelete={toggleModal}
                        loading={loading}
                        onContinue={(type) => HandleContinue(type)}
                    />}
            </div>
            {isModal && <DeleteModal
                loading={isDeleting}
                onDelete={HandleDelete}
                onClose={toggleModal} />}
        </div>
    )
}

export default BuyTicketsNew

function CounterWithTitle({ title, price = '20', value = 0, onSubtract, onAdd }) {
    return (
        <div className='my-3 flex justify-between items-center'>
            <div className='flex'>
                <div className={'text-xs font-interSemiBold w-[6rem]'}>
                    {title}
                </div>
                <div className={'text-xs '}>
                    $ {price} CLP
                </div>
            </div>
            <div className='flex justify-between items-center w-[64px] h-[26px] border border-[black] rounded-full'>
                <button onClick={onSubtract}
                    disabled={value == 0}
                    className={`${value == 0 && 'text-[#D9D9D9]'} py-2 px-2`}>-</button>
                <div className={`text-xs ${value == 0 && 'text-[#D9D9D9] '}`}>{value}</div>
                <button onClick={onAdd}
                    disabled={value == 10}
                    className={`${value == 10 && 'text-[#D9D9D9]'} py-2 px-2`}>+</button>
            </div>
        </div>
    )
}

function Step1({ tickets, HandleSubtract, HandleAdd, onContinue }) {
    const disabled = tickets.some((it) => it?.count > 0)
    return (
        <div>
            <div className='font-interBold text-[1.2rem]'>
                Elige tus entradas
            </div>
            {tickets?.map((i, idx) => {
                return (
                    <CounterWithTitle
                        key={idx}
                        title={i?.name}
                        price={FormatMoney(Math?.trunc(i?.price))}
                        value={i?.count}
                        onAdd={() => HandleAdd(idx, i.ticket_slug)}
                        onSubtract={() => HandleSubtract(idx, i.ticket_slug)} />
                )
            })}
            {/* <div className='italic text-sm mt-[2svh] w-[16rem]'>
                {'En caso de duda escríbenos a contacto@mintapp.info'}
            </div> */}
            <div className='flex justify-center' >
                <BasicButton
                    disabled={!disabled}
                    className={'mt-[4svh]'}
                    onClick={onContinue}>
                    <div className='text-2xl'>{'Continuar'}</div>
                </BasicButton>
            </div>
        </div>
    )
}

function Step2({ onChangeFullName, fullName, rut, onChnageRut, onChangeEmailOrPhone, emailOrPhone, onContinue }) {
    const disabled = empty(fullName) || empty(rut) || empty(emailOrPhone)
    return (
        <div className=''>
            <div className='text-lg font-interBold '>Datos del Comprador</div>
            <p className='text-xs italic text-[#1C1C1C] w-4/5 mb-[2svh]'>
                {'Las entradas se enviarán al correo electrónico del comprador.'}
            </p>
            <div className='mt-[2svh]' />
            <InputBordered placeholder='Nombre Completo *' value={fullName} onChange={onChangeFullName} />
            <InputBordered placeholder='RUT *' value={rut} onChange={onChnageRut} />
            <InputBordered placeholder='Correo Electrónico o Número de Teléfono *' value={emailOrPhone} onChange={onChangeEmailOrPhone} />
            <div className='flex justify-center' >
                <BasicButton
                    disabled={disabled}
                    className={'mt-[2svh]'}
                    onClick={onContinue}>
                    <div className='text-2xl'>Continuar</div>
                </BasicButton>
            </div>
        </div>
    )
}

function ArrayOfSteps({ handleGuestChange, onContinue, data, currentTicket }) {
    const disabled = data?.some((f) => empty(f.name))

    return (
        <div >
            <div className='text-lg font-interBold mb-1'>Entradas</div>
            <p className='text-xs italic text-[#1C1C1C] w-4/5 mb-2' > {'Las entradas se enviarán al correo electrónico del comprador.'}</ p>
            <div className='text-base font-interBold mt-5 mb-2'>{`Asistentes ${currentTicket?.name} *`}</div>
            <div className='mt-2 ' />
            {
                data?.map((i, idx) => {
                    return (
                        <InputBordered
                            placeholder={`Nombre Completo Asistente ${idx + 1} *`}
                            value={i?.name}
                            onChange={(e) => handleGuestChange(idx, 'name', currentTicket?.ticket_slug, e.target.value)} />
                    )
                })
            }
            <div className='flex justify-center' >
                <BasicButton
                    disabled={disabled}
                    className={'mt-[2vh]'}
                    onClick={() => {
                        onContinue(data)
                        // setData(arrayOfObjects)
                    }}>
                    <div className='text-2xl'>Continuar</div>
                </BasicButton>
            </div>
        </div >
    )
}

function PaymentStep({ cards, type, loading, onContinue, HandleDelete, totlCount, total }) {
    let isCard = cards[0]?.CreditCardType !== undefined
    return (
        <div>
            <h2 className='text-xl font-interRegular font-bold'>Medio de Pago</h2>
            <div className='mt-[1vh]'>
                {/*TODO wait for backend to change text*/}
                <p className='w-full h-[4vh] rounded-md bg-[#D9D9D9] flex font-interMedium items-center text-xs pl-4 '>
                    {/* {`${option_description} $ ${price} CLP`} */}
                    {`${totlCount} entradas por $${FormatMoney(total)} CLP`}
                </p>
                {isCard ?
                    <Option
                        className='pb-[3vh] border-b-2'
                        title={'Pagar con tarjeta registrada'}
                        text={
                            <div
                                onClick={HandleDelete}
                                className='flex'>
                                {`${String(cards[0]?.CreditCardType).toUpperCase()} ....${cards[0]?.CreditCardLastNumbers}`}
                                <img className='ml-2 h-[18px]'
                                    src={Icons.bin} />
                            </div>
                        }
                        loading={loading && type == 2}
                        onClick={() => onContinue(2)}
                    />
                    : <Option
                        className='pb-[3vh] border-b-2'
                        title={'Registrar tarjeta '}
                        text={'¡Facilita tus próximos pagos!'}
                        loading={loading && type == 3}
                        onClick={() => onContinue(3)}
                    />}
                <WebPayButton
                    loading={loading && type == 1}
                    onClick={() => onContinue(1)}
                    title={<div>Pagar <u>sin</u> registrar tarjeta</div>}
                />
            </div>
        </div >
    )
}

function Steps({ step, ShowArray, showPayment }) {
    return (
        <ul className='flex justify-between mt-[4svh] mb-[2svh] text-[0.6rem]'>
            <li className={`${step === 1 || step == 2
                || ShowArray ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                1. Asistentes
            </li>
            <li className={`${showPayment ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                2. Medio de Pago
            </li>
            <li className={`text-black/[.3] text-[.62rem] font-interRegular`}>
                3. Entradas
            </li>
        </ul>
    )
}

const Option = ({ title, text, onClick, loading, className }) => {
    return (
        <div className={`w-full flex mt-5 ${className}`}>
            <div className='grid grid-cols-2 w-full '>
                <div className='text-base font-interBold col-span-2 leading-6'>
                    {title}
                </div>
                {text && (
                    <p className='col-span-3 text-[.7rem] font-interRegular'>{text}</p>
                )}
            </div>
            <div className='ml-2'>
                <ArrowButton loading={loading} onClick={onClick} />
            </div>
        </div>
    );
};


function createArray(count, slug) {
    const arrayOfObjects = [];

    for (let i = 0; i < count; i++) {
        const newObj = { name: '', ticket_layer: slug };
        arrayOfObjects.push(newObj);
    }

    return arrayOfObjects;
}